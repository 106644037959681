import { logException } from '@/utils/ow-sentry.js'

export let RESOLVED_MANIFEST = null
export const MANIFEST = new Promise((resolve, reject) => {
  if (!window.overwolf) {
    reject(new Error('Overwolf not found'))
  }

  window.overwolf.extensions.getManifest('cfibielmohaficehaapcpkfbkngoidgdllolkgbo', response => {
    if (!response.success) {
      logException(new Error(`Failed to get manifest: ${response.error}`), {
        extra: { response },
      })
      return resolve(null)
    }
    RESOLVED_MANIFEST = response
    resolve(response)
  })
})

export const CURRENT_WINDOW = window.overwolf
  ? new Promise((resolve, reject) =>
      window.overwolf.windows.getCurrentWindow(result => {
        if (result.success) {
          resolve(result.window)
        } else {
          reject(new Error(result.error || 'Failed to get current window'))
        }
      })
    )
  : Promise.reject(new Error('Not in overwolf'))
